import { DiscogsRelease, DiscogsSearchResult, DiscogsSearchResults, PlaylistResponseItem, SongInfo, YoutubeResponseItem } from "@/types"

// refactor to own class later
const getResultsWithMatrixInfo = function (results: DiscogsSearchResults): Array<DiscogsSearchResult> {
  const resultsWithMatrixInfo: Array<DiscogsSearchResult> = []
  if (results.results) {
    const tracks = results.results
    tracks.forEach((t: DiscogsSearchResult) => {
      if (t.format.includes("Shellac") && t.barcode.length > 1) {
        resultsWithMatrixInfo.push(t)
      }
    })
  }

  return resultsWithMatrixInfo
}

function isRelease(obj: any): obj is DiscogsRelease {
  return obj.tracklist !== undefined
}

const listTrackInfo = async function (records: Array<DiscogsSearchResult>, api: any, cb: any | null = null): Promise<Array<{ text: string; link: string; }>> {
  const tracklist: Array<{ text: string; link: string; }> = []

  let i = 0
  for (const r of records) {
    let trackA = ''
    let trackB = ''

    if (r.year) {
      trackA += r.year.toString() + ' - '
      trackB += r.year.toString() + ' - '
    } else {
      trackA += '0000 - '
      trackB += '0000 - '
    }

    await api
      .getResource(r.resource_url)
      .then((response: unknown) => {
        if (isRelease(response)) {
          if (response.tracklist.length === 2) {
            const _trackA = response.tracklist.find(t => t.position === 'A' || t.position === 'R') || response.tracklist[0]
            const _trackB = response.tracklist.find(t => t.position === 'B' || t.position === 'L') || response.tracklist[1]

            if (_trackA) {
              trackA += _trackA.title + ' - '
            } else {
              trackA += '<Title> - '
            }

            if (_trackB) {
              trackB += _trackB.title + ' - '
            } else {
              trackB += '<Title> - '
            }

            const _artist = r.title.split(' - ')[0].trim()
            trackA += _artist + ' - ('
            trackB += _artist + ' - ('

            if (response.labels && response.labels.length > 0) {
              const label = response.labels[0].name
              trackA += label + ' '
              trackB += label + ' '
            } else if (r.label) {
              trackA += r.label + ' '
              trackB += r.label + ' '
            } else {
              trackA += 'Unknown label '
              trackB += 'Unknown label '
            }

            if (response.labels && response.labels.length > 0) {
              const catno = response.labels[0].catno
              trackA += catno + ', '
              trackB += catno + ', '
            } else if (r.catno) {
              trackA += r.catno + ', '
              trackB += r.catno + ', '
            } else {
              trackA += 'unknown catalog no., '
              trackB += 'unknown catalog no., '
            }

            if (response.identifiers && response.identifiers.length >= 2) {
              const half = Math.ceil(response.identifiers.length / 2)
              const matrixA = response.identifiers.splice(0, half)
              const matrixB = response.identifiers.splice(-half)

              matrixA.forEach(m => {
                trackA += m.value + ', '
              })

              matrixB.forEach(m => {
                trackB += m.value + ', '
              })
            } else {
              trackA += 'matrix unknown, '
              trackB += 'matrix unknown, '
            }

            if (response.notes && response.notes !== '') {
              const notes = response.notes.replace(/\n/g, ' ').replace(/\s{2,}/g, ' ').trim()
              trackA += notes + ')'
              trackB += notes + ')'
            } else {
              trackA = trackA.slice(0, -2) + ')'
              trackB = trackB.slice(0, -2) + ')'
            }

            tracklist.push({ text: trackA, link: response.uri })
            tracklist.push({ text: trackB, link: response.uri })
          }
        }
      })
      .then(() => {
        i = i + 1
        if (cb) {
          return new Promise(resolve => {
            cb(i)
            setTimeout(resolve, 2000)
          })
        } else {
          return Promise.resolve()
        }
      })
  }

  return tracklist
}

const getListOfYoutubeUrls = function (items: Array<unknown>): Array<string> {
  const ids = items.map((i: unknown) => {
    if ((i as any).id.videoId) {
      return `https://www.youtube.com/watch?v=${(i as YoutubeResponseItem).id.videoId}`
    } else {
      return `https://www.youtube.com/watch?v=${(i as PlaylistResponseItem).contentDetails.videoId}`
    }
  });
  return ids;
}

const getListOfYoutubeUrlsExtraInfo = function (items: Array<unknown>): Array<string> {
  const ids = items.map((i: unknown) => {
    if ((i as any).id.videoId) {
      return `${(i as YoutubeResponseItem).snippet.title}\thttps://www.youtube.com/watch?v=${(i as YoutubeResponseItem).id.videoId}`
    } else {
      return `${(i as PlaylistResponseItem).snippet.title}\thttps://www.youtube.com/watch?v=${(i as PlaylistResponseItem).contentDetails.videoId}`
    }
  });
  return ids;
}

const formatArtistName = function (name: string): string {
  /*
  Eugen Rodominsky (composer) -> Eugen Rodominsky composer)
  A. Kunert (lyricist) -> A. Kunert lyricist)
  Louis Dornay (vocalist : tenor vocal) -> Louis Dornay tenor)
  Nathaniel Shilkret (conductor) -> Nathaniel Shilkret conductor) 
  Ted Levy (conductor) -> Ted Levy conductor)
  */

  const matchTextInBrackets = name.match(/\(([^)]+)\)/)
  let textInBrackets = matchTextInBrackets ? matchTextInBrackets[1] : null

  // format might be De Luca, Giuseppe -- Vocalist -- Baritone Vocal
  const hasDoubleDash = name.includes("--")

  if (!textInBrackets && !hasDoubleDash) {
    return name
  }

  if (textInBrackets) {
    if (textInBrackets.includes(':')) {
      if (textInBrackets.toLocaleLowerCase().startsWith('vocalist') || textInBrackets.toLocaleLowerCase().startsWith('instrumentalist')) {
        textInBrackets = textInBrackets.replace('vocalist', '')
        textInBrackets = textInBrackets.replace('Vocalist', '')
        if (!textInBrackets.toLocaleLowerCase().includes('bass vocal')) {
          textInBrackets = textInBrackets.replace('Vocal', '')
          textInBrackets = textInBrackets.replace('vocal', '')
        }
        textInBrackets = textInBrackets.replace('instrumentalist', '')
        textInBrackets = textInBrackets.replace('Instrumentalist', '')
        textInBrackets = textInBrackets.replace(' : ', '')
        textInBrackets = textInBrackets.replace(' :', '')
        textInBrackets = textInBrackets.replace(': ', '')
        textInBrackets = textInBrackets.replace(':', '')
        textInBrackets = textInBrackets.trim()
      }
    }

    const matchFullName = name.match(/^([^(]+)\s*\(/)
    const fullName = matchFullName ? matchFullName[1] : null

    if (!fullName) return textInBrackets

    return fullName.trim() + ' ' + textInBrackets + ')'
  }

  if (hasDoubleDash) {
    const split = name.split("--")

    let fullName = '', category, type = ''

    if (split.length == 3) {
      [fullName, category, type] = split
    } else if (split.length == 2) {
      [fullName, type] = split
    } else {
      return name
    }

    // flip full name
    const formattedName = fullName.includes(', ') ? fullName.trim().split(', ').reverse().join(' ') : fullName.trim()

    // get musician type
    let formattedType = type

    if (formattedType.toLocaleLowerCase().includes('vocal') && !formattedType.toLocaleLowerCase().includes('bass vocal')) {
      formattedType = formattedType.replace('Vocal', '')
      formattedType = formattedType.replace('vocal', '')
    }

    return formattedName.trim() + ' ' + formattedType.trim() + ')'
  }

  return name
}

function sortArtists (names: Array<string>): Array<string> {
  let vocalList = [], instrumentalistList = [], conductorList = [], composerList = [], otherList = []

  for (let i = 0; i < names.length; i++) {
    if (names[i].toLocaleLowerCase().includes('vocal')) {
      vocalList.push(names[i])
    } else if (names[i].toLocaleLowerCase().includes('instrument')) {
      instrumentalistList.push(names[i])
    } else if (names[i].toLocaleLowerCase().includes('conductor')) {
      conductorList.push(names[i])
    } else if (names[i].toLocaleLowerCase().includes('composer')) {
      composerList.push(names[i])
    } else {
      otherList.push(names[i])
    }
  }

  return [vocalList, instrumentalistList, conductorList, composerList, otherList].flat()
}

function convertDate (dateStr: string): string {
  try {
    // Split the input date by the hyphen (-)
    const [year, month, day] = dateStr.split('-');
    
    // Return the date in DD.MM.YYYY format
    return `${day}.${month}.${year}`;
  } catch (e) {
    return dateStr;
  }
}

function formatLabelInfo (songInfo: SongInfo): Array<string> {
  let formattedLabels = []
  
  if (songInfo.recordingTakeNumber.length <= 1) {
    const recordingLabel = songInfo.recordingLabel[0] ?? ''
      const recordingCatalogNumber = songInfo.recordingCatalogNumber[0] ?? ''
      const matrix = songInfo.recordingMatrixNumber[0] ? songInfo.recordingMatrixNumber[0].replace(" (Matrix ID)", "") : ''
      const take = songInfo.recordingTakeNumber[0] ? '-' + songInfo.recordingTakeNumber[0] : ''
      const date = convertDate(songInfo.recordingDate[0]) ?? ''
      return [`${recordingLabel} ${recordingCatalogNumber}, ${matrix}${take}, ${date}`]
      
  }

  formattedLabels = songInfo.recordingTakeNumber.map((take, index) => {
      const recordingLabel = songInfo.recordingLabel[0] ?? ''
      const recordingCatalogNumber = songInfo.recordingCatalogNumber[index] ?? ''
      const matrix = songInfo.recordingMatrixNumber[0] ? songInfo.recordingMatrixNumber[0].replace(" (Matrix ID)", "") : ''
      const date = convertDate(songInfo.recordingDate[index])
      return `${recordingLabel} ${recordingCatalogNumber}, ${matrix}-${take}, ${date}`;
  });
  
  return formattedLabels
}

export {
  getResultsWithMatrixInfo,
  listTrackInfo,
  getListOfYoutubeUrls,
  getListOfYoutubeUrlsExtraInfo,
  formatArtistName,
  sortArtists,
  convertDate,
  formatLabelInfo
}
